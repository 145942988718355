import React from 'react'


const About = () => (
  <test>
  text
  </test>
)

export default About
